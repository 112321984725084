$radius: 10px
$primary: #FF9933
$tonal-primary: darken($primary, 20%)
$disabled: #ddd
$em: 20px
$line-height: 1.5
$height: $line-height * $em
$full-height: 2 * $em
$font: 'Arima Madurai'
$light: 300
$regular: 400
$bold: 500
$black: 700
$red: red
$darkest: rgba(10,10,10,.9)
$grey: rgba(0,0,0,.5)

html
	background-color: #F2FFEC
body
	// width: 62vw // see screen sizes
	max-width: 1200px
	margin: 0 auto
	font-size: $em
	font-family: $font
	font-weight: $light
	line-height: 1.4

footer
	margin-top: $em * 5
	margin-bottom: $height
	text-align: center
	font-family: sans-serif
	font-size: $em*3/4
	line-height: $height*3/4
	a, a:hover
		font-weight: $black
h1, h2, h3, h4
	font-weight: $black

//.center
//	text-align: center

button, .btn
	background: none
	border-radius: $radius
	background-color: $primary
	outline: none !important
	padding: 4px 10px 0px
	border: none
	color: white
	border-bottom: 2px solid rgba(0,0,0,.2)
	font-size: $em
	font-family: $font
	margin: 1px 0
	cursor: pointer
	vertical-align: bottom
	&:disabled
		background-color: $disabled
		cursor: not-allowed
	&:hover
		border-bottom: 2px solid rgba(0,0,0,.3)
	&:focus, &:active
		margin-top: 2px
		border-bottom: 1px solid rgba(0,0,0,.4)
.form div
	margin-bottom: $em/2
.form-section div
	margin-bottom: 0
	label
		border-left: 2px solid rgba(0,0,0,.3)
		padding-left: $em/2
input, textarea, select, .StripeElement
	background-color: rgba(255,255,255,.4)
	border: none
	border-bottom: 2px solid rgba(0,0,0,.3)
	border-radius: $radius/2 //2px
	outline: none
	padding: 10px 10px
	min-height: $full-height
	vertical-align: bottom
	&:hover
		background-color: rgba(255,255,255,.6)
		border-bottom-color: rgba(0,0,0,.4)
	&:focus
		background-color: rgba(255,255,255,.9)
		border-bottom-color: $primary
textarea, select[multiple]
	vertical-align: text-top
	height: $height * 4
	font-family: sans-serif
	padding: $em/2
input[type=number]
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button 
		-webkit-appearance: none
		margin: 0


.hidden
	display: none

.social
	position: relative
	text-align: right
	top: 47px
	height: 0
	*
		font-weight: unset !important
	li
		display: inline-block
		margin-left: 10px
		// a:hover::after
		// 	content: "\269C"
		// 	color: $primary
		// 	position: absolute
		// 	width: 0
		// 	height: 0
		// 	font-size: 30px
		// 	margin-left: -25px
		// 	margin-top: 42px

@mixin adorn
	&::before, &::after
		font-weight: initial
		color: $primary
		position: absolute
	&::after
		content: '\2767'
		margin-left: 10px
	&::before
		content: '\2619'
		margin-left: -27px

nav
	ul
		vertical-align: top
@media screen and ( min-width: 1200px )
	nav
		.menu
			margin-top: 120px
			// a:hover
			// 	@include adorn
	.adorn:hover, a:hover .adorn
		@include adorn

.adorn2:hover
	text-decoration: $primary underline wavy


a
	color: black
	text-decoration: none
	font-weight: $regular
	cursor: pointer
	&.mute
		color: $grey
	&.disabled
		color: $grey !important
		cursor: default !important
		font-weight: $regular !important
		&::before, &::after
			content: none !important
	&:hover
		color: black
		// font-weight: $bold
		// text-decoration: underline dotted
	&.active, &:active
		font-weight: $black
	&.active.disabled
		font-weight: $black !important
		color: black !important

.e
	font-weight: $bold
	margin-left: 25px
	&::before
		content: '\00D7'
		color: $red
		font-weight: $black
		font-size: $em*1.66
		position: absolute
		margin-left: -20px

body
	width: 82vw
nav
	.brand
		width: 180px
		margin-left: -30px
		img
			width: 100%
@media screen and ( max-width: 549px )
	.menu
		text-align: center


@media screen and ( min-width: 550px )
	body
		width: 72vw
	nav
		.brand
			width: 240px
			margin-left: -80px
			img
				width: 100%
.w5, .or-w5
	width: 100%
.or-w1-2
	display: inline-block
	width: 50%

@media screen and ( max-width: 1199px )
	.hide-small
		display: none !important
@media screen and ( min-width: 1200px )
	body
		width: 62vw
	nav
		.brand
			width: 300px
			margin-left: -103px
			img
				width: 100%
	.g
		display: inline-block
	.w1
		width: 20%
	.w2
		width: 40%
	.w3
		width: 60%
	.w4
		width: 80%
	.w5
		width: 100%
	.w1-2
		width: 50%
	.w1-3
		width: (100%/3)
	.w2-3
		width: (100%*2/3)
	.m1
		margin-left: 20%
	.m2
		margin-left: 40%
	.m3
		margin-left: 60%
	.m4
		margin-left: 80%

.mt
	margin-top: $height
.mb
	margin-bottom: $height
.mt-em
	margin-top: $em
.mr
	margin-right: $radius
.ht
	min-height: $full-height

.pad
	padding: $em / 2

.round
	display: inline-block
	border-radius: $radius
	overflow: hidden
	line-height: 0
.round-ends > *
	overflow: hidden
	line-height: 0
	border-radius: 0
	&:first-child
		border-top-left-radius: $radius
		border-bottom-left-radius: $radius
	&:last-child
		border-top-right-radius: $radius
		border-bottom-right-radius: $radius

$hero-height: 550px
.hero
	height: $hero-height
	width: 100%
	background-color: #aaa
	background-image: url('https://firebasestorage.googleapis.com/v0/b/sunburstridgefarm.appspot.com/o/store%2Fstate%2F0.jpg?alt=media')
	background-size: cover
	background-position: center

button.clear
	background: none
	border-bottom-color: rgba(0,0,0,0)
	color: black

.tag
	border-radius: 100px
	&.unselected
		background: none
		border-bottom-color: rgba(0,0,0,0)
		color: black
	&.selected
		color: white
		&::after
			content: '\00D7'
			padding-left: 3px
.tags
	min-height: $full-height
	& > *
		vertical-align: middle

.center
	text-align: center
.right
	text-align: right
.vcenter > *
	vertical-align: middle
.vtop > *
	vertical-align: top
.vbottom > *
	vertical-align: bottom
.small
	font-size: $em * .66667

.click
	cursor: pointer

.error
	background-color: #d34545
	color: white
	border-radius: $radius
	text-align: center
	padding: $em/2

.info
	border: 2px solid #cde0c4
	border-radius: $radius
	text-align: center
	padding: $em/2

button.mute
	background: none
	color: black
	border-top: 1px solid rgba(0, 0, 0, 0.2)
	border-right: 1px solid rgba(0, 0, 0, 0.2)
	border-left: 1px solid rgba(0, 0, 0, 0.2)

.cart-qty
	width: 100%
	max-width: 200px
	display: inline-block
	text-align: center
	// height: 130px
	.plus-minus
		width: 21%
	.qty
		width: 58%
		text-align: center

.point-at:before
	font-size: 1.3*$em
	color: $primary
	content: '\261B'
	position: absolute
	margin-left: -40px

.color
	color: $primary

.legal
	font-family: sans-serif
	h1, h2, h3, h4, h5, p
		margin: 15px 0 15px 0
	ol, ul
		margin: 10px 0 10px 30px
		list-style: disc
		list-style-position: outside
		ol, ul
			list-style: circle
			margin-left: 30px

.hover:hover
	background-color: rgba(0,0,0,0.1)

.thumbnail
	background-position: center
	// background-repeat: no-repeat
	width: 100%
	aspect-ratio: 1 / 1
	@supports not (aspect-ratio: 1 / 1)
		height: 180px